import React from "react";

import { ReactComponent as FaceBookIcon } from "../assets/icons/facebook.svg";
import { ReactComponent as LinkedInIcon } from "../assets/icons/linkedin.svg";
import { ReactComponent as InstagramIcon } from "../assets/icons/instagram.svg";
import { ReactComponent as TwitterIcon } from "../assets/icons/twitter.svg";

export default function ContactSection({ currentUser }) {
  const createVCard = () => {
    const {
      details: {
        FirstName,
        LastName,
        Phone,
        Fax,
        Email,
        Street,
        City,
        State,
        PostalCode,
        Title,
      },
    } = currentUser;

    var vCard = "BEGIN:VCARD" + "\n";
    vCard += "VERSION:3.0" + "\n";
    vCard += "N:" + LastName + ";" + FirstName + "\n";
    vCard += "FN:" + FirstName + " " + LastName + "\n";
    vCard += "ORG:" + currentUser.branding.name + "\n";
    vCard += "TITLE:" + Title + "\n";
    vCard += "TEL;TYPE=WORK,VOICE:" + Phone + "\n";
    vCard += "TEL;TYPE=WORK,FAX:" + Fax + "\n";
    vCard += "EMAIL;TYPE=PREF,INTERNET:" + Email + "\n";
    vCard +=
      "ADR;TYPE=WORK:;;" +
      Street +
      ";" +
      City +
      ";" +
      State +
      ";" +
      PostalCode +
      ";;\n";
    vCard += "TEL;TYPE=CELL:" + currentUser.details.Marketing_Number__c + "\n";
    vCard += "END:VCARD";

    return vCard;
  };

  const downloadVCard = () => {
    var vCard = createVCard();
    var vCardURL = "data:text/vcard;charset=utf-8," + encodeURIComponent(vCard);
    var vCardLink = document.createElement("a");
    vCardLink.setAttribute("href", vCardURL);
    vCardLink.setAttribute(
      "download",
      `${currentUser.details.FirstName}` +
        "_" +
        `${currentUser.details.LastName}` +
        ".vcf"
    );
    vCardLink.click();
  };
  return (
    <div>
      <div className="bg-white mb-4 md:px-8 py-12">
        <h2 className="text-3xl font-bold text-left text-blue-primary sm:text-4xl">
          Contact Information
        </h2>

        <div className="flex flex-col sm:flex-row space-x-0 md:space-x-8">
          <div className="flex flex-col mt-8 sm:w-1/3">
            {currentUser?.details?.Marketing_Number__c && (
              <>
                <p className="text-left text-blue-primary text-xl">Direct:</p>
                <h3 className="text-xl font-bold text-left text-blue-primary sm:text-2xl ">
                  {currentUser?.details?.Marketing_Number__c}
                </h3>
              </>
            )}

            {currentUser?.details?.Phone && (
              <>
                <p className="text-left text-blue-primary mt-4  md:mt-16 text-xl">
                  Office
                </p>
                <h3 className="text-xl font-bold text-left text-blue-primary sm:text-2xl ">
                  {currentUser?.details?.Phone}
                </h3>
              </>
            )}

            {currentUser?.details?.Fax && (
              <>
                <p className="text-left text-blue-primary mt-4 md:mt-16 text-xl">
                  Fax:
                </p>
                <h3 className="text-xl font-bold text-left text-blue-primary sm:text-2xl">
                  {currentUser?.details?.Fax}
                </h3>
              </>
            )}
          </div>
          <div className="flex flex-col mt-8 sm:w-1/3">
            <p className="text-left text-blue-primary text-xl">Email:</p>
            <h3 className="text-xl font-bold text-left text-blue-primary sm:text-2xl">
              {currentUser?.details?.Email}
            </h3>

            {currentUser?.details?.Street ? (
              <>
                <p className="text-left text-blue-primary mt-4 md:mt-16 text-xl">
                  Address
                </p>
                <h3 className="text-xl font-bold text-left text-blue-primary sm:text-2xl ">
                  {currentUser?.details?.Street}
                </h3>
                <h3 className="text-xl font-bold text-left text-blue-primary sm:text-2xl">
                  {currentUser?.details?.City}, {currentUser?.details?.State}{" "}
                  {currentUser?.details?.PostalCode}
                </h3>
              </>
            ) : (
              <div
                className="flex flex-col justify-center items-center mr-12 mt-6"
                onClick={downloadVCard}
              >
                <button
                  style={{
                    backgroundColor: `#${currentUser?.branding?.secondaryColor}`,
                  }}
                  className={`text-white font-[500] py-2 px-4 rounded-md w-1/2  -mb-1 text-xl`}
                >
                  Download vCard
                </button>
                <img
                  src={currentUser?.branding?.vcardImage}
                  alt="user"
                  className="w-auto h-36  m-8 sm:mt-0"
                />
              </div>
            )}
            <div className="flex flex-row  mt-12 w-64">
              {currentUser?.details?.Facebook__c && (
                <div>
                  <a
                    href={currentUser?.details?.Facebook__c}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaceBookIcon
                      className="inline-block mr-4 text-blue-primary"
                      style={{ width: "37px", height: "37px", fill: "#2D536F" }}
                    />
                  </a>
                </div>
              )}
              {currentUser?.details?.LinkedIn__c && (
                <div>
                  <a
                    href={currentUser?.details?.LinkedIn__c}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkedInIcon
                      className="inline-block mr-4 text-blue-primary"
                      style={{ width: "37px", height: "37px", fill: "#2D536F" }}
                    />
                  </a>
                </div>
              )}
              {currentUser?.details?.Instagram__c && (
                <div>
                  <a
                    href={currentUser?.details?.Instagram__c}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramIcon
                      className="inline-block mr-4 text-blue-primary"
                      style={{ width: "37px", height: "37px", fill: "#2D536F" }}
                    />
                  </a>
                </div>
              )}
              {currentUser?.details?.Twitter__c && (
                <div className="flex justify-center items-center">
                  <a
                    href={currentUser?.details?.Twitter__c}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TwitterIcon
                      className="inline-block mr-4 text-blue-primary"
                      style={{ width: "28px", height: "28px", fill: "#2D536F" }}
                    />
                  </a>
                </div>
              )}
            </div>
          </div>
          {currentUser?.details?.Street ? (
            <div className="flex flex-col mt-8 sm:w-1/3 items-center justify-end">
              <div onClick={downloadVCard}>
                <button
                  style={{
                    backgroundColor: `#${currentUser?.branding?.secondaryColor}`,
                  }}
                  className={` text-white font-[500] py-2 px-4 rounded-md w-full -mb-1 text-xl`}
                >
                  Download vCard
                </button>

                <img
                  src={currentUser?.branding?.vcardImage}
                  alt="user"
                  className="w-auto h-48 mt-6 sm:mt-0"
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
