import React, { useEffect, useLayoutEffect, useState } from "react";
import { MenuIcon, XIcon } from "@heroicons/react/solid"; // we will use Heroicons for hamburger and close icons

const NavBar = ({ currentUser }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [modifiedSections, setModifiedSections] = useState([]);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(true); // state for mobile menu visibility

  let sections = currentUser?.customizations?.customizations?.current.list;

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isVisible = prevScrollPos > currentScrollPos;

      setIsVisible(isVisible);
      setPrevScrollPos(currentScrollPos);
      // setIsMobileMenuOpen(false);
      if (window.innerWidth < 1024) {
        setIsMobileMenuOpen(false);

        return;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(() => {
    if (!sections) return;
    convertSections(sections);
  }, [sections]);

  const convertSections = (sections) => {
    // remove "Contact Me" from sections
    const filteredSections = sections.filter(
      (section) => section !== "Contact Me"
    );

    // create new objects for each section with id and name properties
    const updatedSections = filteredSections.map((section) => {
      let id = section;
      let name = section;

      if (section === "Construction Home Loans") {
        name = "Construction Loans";
      } else if (section === "Portfolio Home Loans") {
        name = "Portfolio Loans";
      } else if (section === "Traditional Loan Programs") {
        name = "Traditional Loans";
      } else if (section === "Meet The Team") {
        name = "Team";
      } else if (section === "Guide Section") {
        name = "Guides";
      } else if (section === "Reverse Mortgage Section") {
        name = "Reverse Mortgages";
      } else if (section === "Read What Clients Have To Say") {
        name = "Reviews";
      }

      return { id, name };
    });

    return setModifiedSections(updatedSections);
  };
  useLayoutEffect(() => {
    const updateScreenSize = () => {
      if (window.innerWidth > 1024) {
        setIsMobileMenuOpen(true);

        return;
      }
    };

    window.addEventListener("resize", updateScreenSize);

    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  if (!sections) return null;

  return (
    <nav
      className={`fixed top-0 right-0 lg:w-4/5 min border bg-white transition-transform duration-300 overflow-hidden  ${
        isVisible ? "translate-y-0" : "-translate-y-full"
      }`}
    >
      <div className="flex items-center justify-between px-4 py-3 lg:hidden">
        <div className="ml-auto">
          <button
            type="button"
            className="block text-gray-500 hover:text-white focus:text-white focus:outline-none"
            onClick={toggleMobileMenu}
          >
            {isMobileMenuOpen ? (
              <XIcon className="h-6 w-6 text-red-700" />
            ) : (
              <MenuIcon className="h-6 w-6 text-blue-primary" />
            )}
          </button>
        </div>
      </div>
      <div
        className={`${
          isMobileMenuOpen ? "block" : "hidden"
        } px-2 pt-2 pb-4  sm:p-0`}
      >
        <ul className="lg:flex lg:flex-row lg:justify-evenly lg:items-center">
          {modifiedSections?.map((section, index) => {
            return (
              <a key={index} href={`#${section.id}`}>
                <li
                  key={index}
                  className="font-[600] lg:px-4 text-lg text-blue-primary px-4 py-2 border-b lg:border-none lg:p-4 hover:bg-blue-primary hover:text-white transition-all duration-300 "
                >
                  {section.name}
                </li>
              </a>
            );
          })}
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
