import React, { useState } from "react";
import Spinner from "../common/Spinner";
import Notification from "../common/Notification";
import { gql, ApolloClient, InMemoryCache } from "@apollo/client";

export default function ContactMe({ currentUser }) {
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",

    errors: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    },
  });

  const client = new ApolloClient({
    uri: "https://graphql-onetrust.herokuapp.com/",
    cache: new InMemoryCache(),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [showNotificationModal, setNotificationModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [isError, setIsError] = useState(false);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log(form);

    let errors = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
    };
    console.log("erros", errors);
    // Check if all fields are filled
    for (let key in form) {
      if (form[key] === "" && key !== "errors") {
        errors[key] = "This field is required";
      }
    }

    // Validate email field
    if (!emailValidation(form.email)) {
      errors.email = "Invalid email address";
    }

    // Validate phone field
    // if (!onlyAllowNumbers(form.phoneNumber)) {
    //   errors.phoneNumber = "Invalid phone number";
    // }

    // If any errors were found, stop submission and show errors
    if (Object.values(errors).some((x) => x !== "")) {
      setForm((prevState) => ({ ...prevState, errors: errors }));
      setIsLoading(false);
      setIsError(true);
      console.log("errors", errors);
      return;
    } else {
      setIsError(false);
      // if no errors clear the form
      setForm({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        errors: {
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
        },
      });
    }

    let brandName = currentUser.branding.name;
    let userAccountName = currentUser?.customizations?.signatureSiteUniqueID;

    if (!userAccountName) {
      userAccountName = window.location.pathname.split("/").pop();
    }

    let id = currentUser.details.Id;
    console.log(currentUser);
    // Send the form data to the server
    client
      .mutate({
        mutation: gql`
          mutation CreateLoanForceContact(
            $firstName: String!
            $lastName: String!
            $email: String!
            $phoneNumber: String!
            $userAccountName: String!
            $id: String!
            $campaignName: String!
            $campaignStatus: String!
            $source: String!
            $loanPurpose: String!
            $syncWithMarketo: Boolean
          ) {
            createLoanForceContact(
              firstName: $firstName
              lastName: $lastName
              email: $email
              phone: $phoneNumber
              userAccountName: $userAccountName
              id: $id
              campaignName: $campaignName
              campaignStatus: $campaignStatus
              source: $source
              loanPurpose: $loanPurpose
              syncWithMarketo: $syncWithMarketo
            ) {
              firstName
              lastName
              email
              phone
              userAccountName
              id
              campaignName
              campaignStatus
              source
              loanPurpose
            }
          }
        `,
        variables: {
          firstName: form.firstName,
          lastName: form.lastName,
          email: form.email,
          phoneNumber: form.phoneNumber,
          loanPurpose: "", // Assuming loanPurpose is empty, replace with actual value
          campaignName: `${brandName} - MLO Website - Contact form`,
          campaignStatus: "Active",
          source: "MLO Website - Contact form",
          userAccountName: userAccountName,
          id: id,
          syncWithMarketo: true,
        },
      })
      .then((response) => console.log(response))
      .then((data) => {
        console.log("Success:", data);

        setNotificationModal(true);
        setModalData({
          title: "Success!",
          message: "Your information has been sent!",
          type: "success",
        });
        setIsLoading(false);
        setTimeout(() => {
          setNotificationModal(false);
          setModalData({});
        }, 3000);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // Helper functions to validate fields
  const emailValidation = (email) => {
    let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const onlyAllowNumbers = (str) => {
    let regex = /^[0-9\b]+$/;
    return regex.test(str);
  };

  const handlePhoneChange = (e) => {
    // format parentheses, dashes, and spaces
    // only allow numbers
    let input = e.target.value;
    input = input.replace(/[^0-9]/g, "");
    input = input.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    //limit 10 characters
    input = input.substring(0, 14);

    setForm({
      ...form,
      [e.target.name]: input,
    });
  };

  return (
    <div className="bg-gradient-to-b from-blue-primary via-blue-primary to-green-primary">
      <div>
        <h2 className="text-4xl font-bold text-center text-white pt-12 pb-24">
          Get in Touch!
        </h2>
      </div>
      <form
        onSubmit={handleSubmit}
        className="grid grid-cols-1 md:grid-cols-2 md:gap-10 pb-12 place-items-center w-2/3 mx-auto "
      >
        {/* First Name */}
        <div className="flex flex-col w-5/6">
          <label className="text-white font-thin text-left">First Name</label>
          <input
            type="text"
            name="firstName"
            value={form.firstName}
            onChange={handleChange}
            className={`bg-transparent border rounded-lg px-4 py-2 w-full text-white ${
              form.errors.firstName && "border-red-500"
            }`}
          />
          {form.errors.firstName && (
            <p className="text-red-500">{form.errors.firstName}</p>
          )}
        </div>
        {/* Last Name */}
        <div className="flex flex-col w-5/6">
          <label className="text-white font-thin text-left">Last Name</label>
          <input
            type="text"
            name="lastName"
            value={form.lastName}
            onChange={handleChange}
            className={`bg-transparent border rounded-lg px-4 py-2 w-full text-white ${
              form.errors.lastName && "border-red-500"
            }`}
          />
          {form.errors.lastName && (
            <p className="text-red-500">{form.errors.lastName}</p>
          )}
        </div>
        {/* Email */}
        <div className="flex flex-col w-5/6">
          <label className="text-white font-thin text-left">Email</label>
          <input
            type="text"
            name="email"
            value={form.email}
            onChange={handleChange}
            className={`bg-transparent border rounded-lg px-4 py-2 w-full text-white ${
              form.errors.email && "border-red-500"
            }`}
          />
          {form.errors.email && (
            <p className="text-red-500">{form.errors.email}</p>
          )}
        </div>
        {/* Phone Number */}
        <div className="flex flex-col w-5/6">
          <label className="text-white font-thin text-left">Phone Number</label>
          <input
            type="text"
            name="phoneNumber"
            value={form.phoneNumber}
            onChange={(e) => handlePhoneChange(e)}
            className={`bg-transparent border rounded-lg px-4 py-2 w-full text-white ${
              form.errors.phoneNumber && "border-red-500"
            }`}
          />
          {form.errors.phoneNumber && (
            <p className="text-red-500">{form.errors.phoneNumber}</p>
          )}
        </div>
      </form>
      <div className="flex justify-center mt-4">
        {!isLoading && (
          <button
            onClick={handleSubmit}
            type="submit"
            className="bg-[#821B56] text-white text-lg font-bold py-2 px-4 rounded-lg mt-4 w-48"
          >
            Submit
          </button>
        )}
        {isLoading && (
          <div className="flex justify-center">
            <Spinner width="100px" />
          </div>
        )}
      </div>
      <Notification
        show={showNotificationModal}
        setShow={setNotificationModal}
        title={modalData.title}
        message={modalData.message}
        type={modalData.type}
      />
      <p className="text-xs px-12 py-8 text-white font-thin">
        By clicking the 'Get a Quote' button below, I authorize{" "}
        {currentUser.branding.name} to contact me about a mortgage by a live
        agent, artificial or prerecorded voice, and SMS text at my residential
        or cellular number, dialed manually or by autodialer and to be contacted
        via email, even if the telephone number is a cellular phone or other
        service for which the party is charged. I understand that I am not
        required to provide this consent in order to obtain goods or services
        from {currentUser.branding.name}. I agree to receive disclosures and
        communications in electronic form and I confirm that I have a working
        computer system and an internet website browser to view this webpage and
        this loan inquiry form. I agree that {currentUser.branding.name} may
        contact me by mail, telephone or email in connection with my request
        even if my telephone number of email address appears on a{" "}
        {currentUser.branding.name} internal Do Not Call / Do Not Email List, a
        State or National Do Not Call Registry, or Do Not E-Mail list, or any
        other Do Not Contact List.{" "}
      </p>
    </div>
  );
}
