import React from "react";

export default function SpanishBanner({ backgroundColor }) {
  return (
    <div className="pt-4 xl:pt-8">
      <div
        className="text-white text-center py-1 xl:py-2"
        style={{ backgroundColor: `#${backgroundColor}` }}
      >
        <p className="text-lg font-bold">Hablo Español</p>
      </div>
    </div>
  );
}
