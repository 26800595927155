import React from "react";

export default function Button({ text, color }) {
  return (
    <button
      style={{ backgroundColor: `#${color}`, outline: `4px solid #${color}` }}
      className={`hover:bg-blue-700 text-white font-bold py-2 px-4 outline  outline-offset-4 w-48 hover:-translate-y-1 transition-all duration-200`}
    >
      {text}
    </button>
  );
}
