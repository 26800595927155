import { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoute from "../util/PrivateRoute";
import HomePage from "../pages/HomePage";
import ErrorPage from "../pages/ErrorPage";

function App() {
  const BASE_PATH = "/contact";

  useEffect(() => {
    let url = window.location.href;
    let newUrl = url.replace("www.", "");

    // replace
    if (url !== newUrl) {
      window.location.replace(newUrl);
    }
  }, []);
  return (
    <div className="App">
      <Router primary={false} basepath={BASE_PATH} basename={BASE_PATH}>
        <Routes>
          <Route path="/" element={<ErrorPage />} />
          <Route path="/:id" element={<HomePage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
