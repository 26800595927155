import React, { useState, useEffect, useContext } from "react";
import { auth } from "../firebase";

let logoutTimer;

const AuthContext = React.createContext({
  isLoggedIn: false,
  id: "",

  login: () => {},
  logout: () => {},
  signUp: () => {},
  getUser: () => {},
});

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthContextProvider = (props) => {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  function logout() {
    return auth.signOut();
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function signUp(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function forgotPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user);
        setLoading(false);
      } else {
        setCurrentUser(null);
        setLoading(false);
      }

      return unsubscribe;
    });
  }, []);

  const contextValue = {
    currentUser,
    setCurrentUser,
    forgotPassword,
    login: login,
    logout: logout,
    signUp: signUp,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {!loading && props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
