import React from "react";
import Spinner from "../components/common/Spinner";

export default function LoadingPage() {
  // make the ... move

  let dots = "...";
  let [dotsCount, setDotsCount] = React.useState(0);

  React.useEffect(() => {
    let interval = setInterval(() => {
      if (dotsCount === 3) {
        setDotsCount(0);
      } else {
        setDotsCount(dotsCount + 1);
      }
    }, 500);

    return () => clearInterval(interval);
  }, [dotsCount]);

  return (
    <div className="bg-blue-primary flex-col h-screen flex justify-center items-center">
      <h1 className="text-white text-5xl font-bold mb-4 transform -translate-y-12 transition duration-500 ease-in-out animate-pulse ">
        Loading Contact Page{dots.slice(0, dotsCount)}
      </h1>
      <Spinner width={"250px"} />
    </div>
  );
}
