import React from "react";

export default function ErrorPage() {
  // see if the url is a valid OneTrustHomeLoans url
  let isOneTrustHomeLoans = () => {
    let url = window.location.href;

    if (url === "https://onetrusthomeloans.com") {
      return true;
    }

    return false;
  };

  return (
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <h1 className="text-9xl font-bold text-blue-primary">404</h1>
        <h2 className="mt-4 text-3xl font-bold tracking-tight text-blue-primary sm:text-5xl">
          Sorry, this page does not exist.
        </h2>
        <p className="mt-6 text-xl leading-7 text-blue-complimentary">
          Please make sure you have the correct web link.
        </p>

        <div className="mt-10 flex items-center justify-center gap-x-6">
          <a
            href="/"
            className="rounded-md bg-blue-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-complimentary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-complimentary"
          >
            Go back home
          </a>
        </div>
      </div>
    </main>
  );
}
