import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import "./index.css";
import { AuthContextProvider } from "./context/auth-context";
import theme from "./util/theme";
import { ThemeProvider } from "styled-components";
import ErrorBoundary from "./util/ErrorBoundary";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

const client = new ApolloClient({
  uri: "https://graphql-onetrust.herokuapp.com/", // replace with your GraphQL server URI
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <ErrorBoundary>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </ThemeProvider>
    </ApolloProvider>
  </ErrorBoundary>,
  document.getElementById("root")
);
