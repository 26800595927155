import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const app = firebase.initializeApp({
  apiKey: "AIzaSyCUAlm8IftRZZqhL332dR-kJScoChDZJFA",
  authDomain: "mlo-pages.firebaseapp.com",
  projectId: "mlo-pages",
  storageBucket: "mlo-pages.appspot.com",
  messagingSenderId: "20325402914",
  appId: "1:20325402914:web:38a1017aabca4cdb007d7b",
});

export const auth = firebase.auth();

export default app;
