import React from "react";
import styled from "styled-components/macro";

// import helpers

const Spinner = styled.svg`
  animation: rotate 2s linear infinite;

  height: auto;

  & .path {
    stroke: #99c24d;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export default ({ width }) => (
  <Spinner style={{ width: width, maxWidth: '200px' }} viewBox="0 0 50 50">
    <circle
      className="path "
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="4"
    />
  </Spinner>
);
