import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline"; // Import the close icon
import ContactMe from "../dynamic-sections/ContactMe.js";

export default function Example({ currentUser }) {
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10 w-5/6"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <div className="fixed inset-0 z-10 mx-auto overflow-y-hidden w-full md:w-4/5 ">
          <Dialog.Panel className="relative transform overflow-hidden mx-auto md:mx-0 md:ml-auto rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-5/6 md:w-3/4 sm:p-6">
            {/* Close Button */}
            <button
              className="w-8 h-8 bg-white rounded-full absolute top-8 right-7 flex items-center justify-center"
              onClick={() => {
                setOpen(false);
                currentUser.showGetInTouchModal = false;
              }}
            >
              <XIcon className="text-red-500 w-5 h-5" aria-hidden="true" />
            </button>

            <div>
              <ContactMe currentUser={currentUser} />
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
