import React, { Suspense } from "react";
import { convertToReactNamingConvention } from "../util/commonFunctions";

export default function DynamicSections({ ...props }) {
  let { currentUser } = props;

  let customSections =
    currentUser?.customizations?.customizations?.current.list;

  const renderSections = () => {
    return customSections.map((section, index) => {
      let reactId = convertToReactNamingConvention(section);

      const Section = React.lazy(() => import(`./dynamic-sections/${reactId}`));

      const component = <Section currentUser={currentUser} />;

      // every other background color gray
      let bgColor = index % 2 === 1 ? "bg-gray-100" : "bg-white";

      return (
        <div className={`border-b-2  ${bgColor} `} key={index}>
          <Suspense fallback={<div></div>}>{component}</Suspense>
        </div>
      );
    });
  };

  return <div>{customSections && renderSections()}</div>;
}
