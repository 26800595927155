const theme = {
  // OneTrust colors plus additional design colors
  colors: {
    // blues
    primaryBlue: "#2d536f",
    complimentaryBlue: "#5481a3",
    lightBlue: "#59b2d4", // not official OneTrust
    // other colors
    accentGreen: "#99c24d",
    accentOrange: "#eab345",
    accentRed: "#fd5f54",
    // monotones
    black: "#323232",
    white: "#ffffff",
    lightestGrey: "#ebebeb",
    lightGrey: "#d3d3d3",
    grey: "#737373",
    darkGrey: "#696969",
  },
  breakpoints: {
    sm: "76px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1600px",
  },
  typography: {
    // correspond with Google font actual weights for Roboto
    weights: {
      thin: "100",
      light: "300",
      regular: "400",
      medium: "500",
      bold: "700",
      black: "900",
    },
  },
};

// helper functions to ease using theme - components can import just the helpers they need and easily access the theme with them
export const getBreakpoint = (breakpoint) => (props) =>
  props.theme.breakpoints[breakpoint];

export const getColor = (color) => (props) => props.theme.colors[color];

export const getFontWeight = (weight) => (props) =>
  props.theme.typography.weights[weight];

export default theme;
